
window.addEventListener('scroll', () => {
  if(window.pageYOffset > 100) {
    document.querySelector('.header').classList.add('header__nav-solid');
    document.querySelector('.logo').classList.add('logo-solid');
    document.querySelector('.header__actions').classList.add('header__actions-solid');
    document.querySelector('.icon--menu').classList.add('icon--menu__dark');
  } else {
    document.querySelector('.header').classList.remove('header__nav-solid');
    document.querySelector('.logo').classList.remove('logo-solid');
    document.querySelector('.header__actions').classList.remove('header__actions-solid');
    document.querySelector('.icon--menu').classList.remove('icon--menu__dark');
  }
});

const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 800
});

let bodyEl = document.body,
  content = document.querySelector('.body'),
  menu = document.getElementById('menu'),
  openbtn = document.getElementById('open-button'),
  closebtn = document.getElementById('close-button'),
  menuIsOpen = false;


function init() {
  initEvents();
}

function initEvents() {
  openbtn.addEventListener('click', toggleMenu);

  if (closebtn) {
    closebtn.addEventListener('click', toggleMenu);
  }

  // close the menu element if the target is not the menu element or one of its descendants.
  content.addEventListener('click', e => {
    const target = e.target;

    if (menuIsOpen && target !== openbtn && !menu.contains(target)) {
      toggleMenu(e);
    }
  });
}

function toggleMenu(e) {
  e.preventDefault();
  e.stopPropagation();

  menuIsOpen ? bodyEl.classList.remove('menu-isopen') : bodyEl.classList.add('menu-isopen');

  menuIsOpen = !menuIsOpen;
  return false;
}

init();
